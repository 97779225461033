import React, { useState, useRef, useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Context } from "../context";

function Navbar() {
  const [activeItem, setActiveItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);
  const location = useLocation();
  const { context } = useContext(Context);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (e, item) => {
    if (activeItem === item) {
      setActiveItem(null);
    } else {
      setActiveItem(item);
    }
  };

  const login = context.user
    ? context.user.isAdmin
      ? "Administration"
      : context.user.firstName
      ? `Bienvenue ${context.user.firstName}`
      : "Espace client"
    : "Connexion";
  const pageLogin = context.user
    ? context.user.isAdmin
      ? "/admin/home"
      : "/profile"
    : "/login";

  // Determine if the "Services" tab should be active
  const isServicesActive =
    location.pathname.includes("/gardedomicile_vousavezgagné") ||
    location.pathname.includes("/educationcanine") ||
    location.pathname.includes("/baladecanine");

  // const login = props.user ? `Bienvenue ${props.user}` : "Espace client";
  // const pageLogin = props.user ? "/profile" : "/login";

  return (
    <div className="container p-0">
      <nav className="navbar navbar-expand-lg navbar-light py-3">
        <div className="container-fluid p-0 pb-5 justify-content-end">
          <button
            className="navbar-toggler "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#nav02"
            aria-controls="nav02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            ref={navRef}
            className={`container p-0 collapse navbar-collapse ${
              isOpen ? "show" : ""
            }`}
            id="nav02"
          >
            <ul className="navbar-nav mt-0 mt-lg-0 mb-3 mb-lg-0 ms-lg-3 fs-4 d-flex justify-content-between w-100">
              <li
                className="nav-item"
                onClick={(e) => handleClick(e, "Accueil")}
              >
                <NavLink className="nav-link p-0" to="/">
                  Accueil
                </NavLink>
              </li>
              <li
                className="nav-item"
                onClick={(e) => handleClick(e, "Qui suis-je ?")}
              >
                <NavLink className="nav-link  p-0" to="/about-me">
                  Qui suis-je ?
                </NavLink>
              </li>
              <li
                className="nav-item dropdown "
                onClick={(e) => handleClick(e, "Services")}
              >
                <Link
                  className={`nav-link  p-0 dropdown-toggle ${
                    isServicesActive ? "active" : ""
                  }`}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded={isOpen}
                >
                  Services
                </Link>
                <ul
                  className={`dropdown-menu ${
                    activeItem === "Services" ? "show" : ""
                  }`}
                >
                  <li>
                    <Link className="dropdown-item" to="/educationcanine">
                      Education canine
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/baladecanine">
                      Balade canine
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/gardedomicile_vousavezgagné"
                    >
                      Garde à domicile
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item" onClick={(e) => handleClick(e, "Blog")}>
                <NavLink className="nav-link  p-0" to="/blog">
                  Blog
                </NavLink>
              </li> */}
              <li
                className="nav-item"
                onClick={(e) => handleClick(e, "Contact")}
              >
                <NavLink className="nav-link  p-0" to="/contact">
                  Contact
                </NavLink>
              </li>
              {/* <li
                className="nav-text nav-item"
                onClick={(e) => handleClick(e, "Bienvenue")}
              >
                <Link className="nav-link  p-0" to={pageLogin}>
                  {login}
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
