import React from "react";
import logo from "../assets/Icono_-_couleurs_-_fond_bleu_clair.png";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <div>
      <section className="py-5 pb-0">
        <div className="container p-0">
          <div className="container p-0">
            <div className="row p-5 border-bottom shadow background_blue rounded-border ">
              <div className="col-12 mx-auto ">
                <div className="row px-2 d-flex flex-wrap">
                  <div className="col-12 col-md-6 col-xl-3 mb-4 mb-lg-0 ">
                    <p className="d-inline d-flex no-wrap ">
                      <img src={logo} alt="logo Zooethos" width={40} />

                      <span className="fs-4 fw-bolder">ZOOETHOS</span>
                    </p>

                    <ul className="list-unstyled">
                      <li className="mb-2 smallfontsize fw-bold">
                        Éducation canine <br /> Garde à domicile <br /> Balade
                        canine
                      </li>
                      <li className="mb-2 smallfontsize">
                        Méthode bienveillante <br />à l'écoute des animaux
                      </li>
                      <li className="mb-2 smallfontsize">
                        Basée à Rouen
                        <br /> Déplacements 76 & 27
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3 mb-4 mb-md-0 ">
                    <h3 className="h5 mb-4">Me contacter</h3>
                    <ul className="list-unstyled">
                      <li className="mb-2 smallfontsize">Nina GIL</li>
                      <li className="mb-2">
                        <a
                          className="link-dark link-underline-opacity-0 smallfontsize"
                          href="mailto:contactzooethos@gmail.com"
                        >
                          contactzooethos@gmail.com
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="link-dark link-underline-opacity-0 smallfontsize"
                          href="tel:+33777173988"
                        >
                          07 77 17 39 88
                        </a>
                      </li>

                      <li className="mb-2">
                        <i className="bi bi-google"> </i>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="link-dark smallfontsize"
                          href="https://g.page/r/CVpXmvTLvW8QEAE/review"
                        >
                          Laissez votre avis Google !
                        </a>
                      </li>
                      <li>
                        <i className="bi bi-facebook"> </i>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="link-dark smallfontsize"
                          href="https://www.facebook.com/profile.php?id=100076052268054&sk=reviews"
                        >
                          Laissez votre avis Facebook !
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col col-md-6 col-xl-3 mb-4 mb-md-0">
                    <h3 className="h5 mb-4">Mes réseaux sociaux</h3>
                    <ul className="list-unstyled">
                      <li className="me-1 mb-2">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="link-dark link-underline-opacity-0 smallfontsize"
                          href="https://www.facebook.com/profile.php?id=100076052268054"
                        >
                          <i className="bi bi-facebook"> </i>
                          Facebook
                        </a>
                      </li>
                      <li className="me-1 mb-2">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="link-dark link-underline-opacity-0 smallfontsize"
                          href="https://www.instagram.com/zoo.ethos/"
                        >
                          <i className="bi bi-instagram"> </i>
                          Instagram
                        </a>
                      </li>
                      <li className="me-1 mb-2">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="link-dark link-underline-opacity-0 smallfontsize"
                          href="https://www.tiktok.com/@zooethos"
                        >
                          <i className="bi bi-tiktok"> </i>
                          TikTok
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col col-xl-3 mb-4 mb-md-0 ">
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <Link className="link-dark smallfontsize" to="/">
                          Accueil
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          className="link-dark smallfontsize"
                          to="/about-me"
                        >
                          Qui suis-je ?
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          className="link-dark smallfontsize"
                          to="/educationcanine"
                        >
                          Education canine
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          className="link-dark smallfontsize"
                          to="/baladecanine"
                        >
                          Balade canine
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link
                          className="link-dark smallfontsize"
                          to="/gardedomicile_vousavezgagné"
                        >
                          Garde à domicile
                        </Link>
                      </li>

                      <li className="">
                        <Link className="link-dark smallfontsize" to="/contact">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-2">
            <div className="row align-items-center">
              <div className="col-12 mb-4">
                <p className="text-center">
                  <a
                    className="link-dark link-underline-opacity-0 smallfontsize"
                    href="\cgv.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CGV
                  </a>{" "}
                  -{" "}
                  <Link
                    to={"/mentions-légales"}
                    className="link-dark link-underline-opacity-0 smallfontsize"
                    href="#"
                  >
                    Mentions légales & politique de confidentialité
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
